import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import BlogRoll from '../components/blog-roll'

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[`software development`, `tech blog`]}
        title="Home"
        urlPath="/"
      />
      <div className="bg-white overflow-hidden">
        <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div className="absolute inset-0">
            <div className="bg-white h-1/3 sm:h-2/3"></div>
          </div>
          <div className="relative max-w-7xl mx-auto">
            <div className="text-center">
              <h2 className="text-3xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
                Recent Posts
              </h2>
              <p className="mt-3 max-w-2xl mx-auto text-xl leading-7 text-gray-500 sm:mt-4">
                Hi, I&apos;m Parinda, a full-stack software engineer. I write
                about all things related to web development.
              </p>
            </div>

            <div className="mt-6 border-t-2 border-gray-100">
              <BlogRoll />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
