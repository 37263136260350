import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import { kebabCase } from 'lodash'
import freelancerIllustration from '../images/freelancer-illustration.svg'

const dateTime = (str) => {
  const date = new Date(str)
  return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`
}

class BlogRoll extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return posts && posts.length > 0 ? (
      <div
        className="mt-12 grid gap-5 max-w-lg mx-auto lg:grid-cols-3 lg:max-w-none"
      >
        {posts &&
          posts.map(({ node: post }) => (
            <div key={`${post.id}post`}>
              <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                <div className="flex-shrink-0">
                  {post.frontmatter.featuredImage ? (
                    <div className="featured-thumbnail">
                      <img
                        className="h-48 w-full object-cover"
                        src={post.frontmatter.featuredImage}
                        alt=""
                      />
                    </div>
                  ) : null}
                </div>
                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                  <div className="flex-1">
                    {post.frontmatter.tags && post.frontmatter.tags.length ? (
                      <p className="text-sm leading-5 font-medium">
                        {post.frontmatter.tags.map((tag) => (
                          <Link
                            key={tag + `tag`}
                            to={`/topics/${kebabCase(tag)}/`}
                          >
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 mr-2">
                              {tag}
                            </span>
                          </Link>
                        ))}
                      </p>
                    ) : null}
                    <Link className="block" to={post.fields.slug}>
                      <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900">
                        {post.frontmatter.title}
                      </h3>
                      <p className="mt-3 text-base leading-6 text-gray-500">
                        {post.frontmatter.description}
                      </p>
                    </Link>
                  </div>
                  <div className="mt-6 flex items-center">
                    <div className="flex text-sm leading-5 text-gray-500">
                      <time dateTime={dateTime(post.frontmatter.date)}>
                        {post.frontmatter.date}
                      </time>
                      <span className="mx-1">&middot;</span>
                      <span>{post.timeToRead} min read</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    ) : (
      <div className="prose mx-auto my-10 px-4 md:px-0 flex flex-col items-center">
        <img
          alt="Coming Soon svg"
          className="block mx-auto w-3/4 md:w-1/2"
          src={freelancerIllustration}
        />
        <h2>Coming Soon...</h2>
      </div>
    )
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

const Component = () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          limit: 6
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMM DD, YYYY")
                description
                tags
                featuredImage
              }
              timeToRead
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
)

export default Component
